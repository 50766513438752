<template>
  <vue-scroll
    ref="vs"
    :ops="ops"
    class="scroll-bar"
    @handle-scroll="handleScroll"
  >
    <slot />
  </vue-scroll>
</template>

<script>
import VueScroll from 'vuescroll';

export default {
  components: { VueScroll },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ops() {
      return {
        ...this.options,
        bar: {
          ...(this.options || {}).bar,
          onlyShowBarOnScroll: false,
        },
      };
    },
  },
  methods: {
    handleScroll(event) {
      if (event.type === 'vertical' && event.scrollTop === 0) {
        this.$emit('ps-y-reach-start', event);
      }
    },
    scrollTo({ x, y }, speed, easing) {
      this.$refs.vs.scrollTo({ x, y }, speed, easing);
    },
    scrollToBottom(offset, speed, easing) {
      const element = this.$refs.vs.getCurrentviewDom()[0];
      if (element) {
        this.$refs.vs.scrollTo({ y: element.scrollHeight - offset }, speed, easing);
      }
    },
    getPosition() {
      return this.$refs.vs.getPosition();
    },
    getScrollHeight() {
      const scrollElement = this.$refs.vs.getCurrentviewDom()[0];
      return scrollElement?.scrollHeight || 0;
    },
    getOffsetHeight() {
      const scrollElement = this.$refs.vs.getCurrentviewDom()[0];
      return scrollElement?.offsetHeight || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.scroll-bar::v-deep {
  .__bar-is-vertical {
    background: $gray-300 !important; // Essential important
  }
  .__panel {
    padding: 10px 0;
    margin-right: 0;
  }
  .__view {
    .placeholder {
      height: 75vh;
    }
  }
}
</style>
